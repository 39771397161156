export const price = 500_000_000;
export const robot = "4GfZP7fbGZBx5GxR4mreLGTm872vHYhDMmaV6d1SEHGtv7AP";
export const listOfCommands = [
  {
    name: "Wave Hand",
    value: "0x3000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    name: "Wave Hand with Turn",
    value: "0x3100000000000000000000000000000000000000000000000000000000000000"
  },
  {
    name: "Shake Hand",
    value: "0x3200000000000000000000000000000000000000000000000000000000000000"
  }
];
